import basePageFields from '@fragments/pages/basePageFields';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment NewsletterFragment on Newsletter {
        ${basePageFields}
        createdAt
        dek
        updatedAt
        parent {
            relationTo
            value {
                ... on Blog {
                    title
                    dek
                    path
                }
                ... on Program {
                    title
                    dek
                    path
                }
                ... on Project {
                    title
                    dek
                    path
                }
            }
        }
        hero {
            image {
                ...ImageSummaryFragment
            }
        }
        seo {
            ...SeoFragment
        }
    }

    ${ImageSummaryFragment}
    ${SeoFragment}
`;
