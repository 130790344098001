import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment ContentCardBlockFragment on ContentCardBlock {
        blockName
        blockType
        content {
            ...SummaryFragment
        }
        overrideImage {
            ...ImageSummaryFragment
        }
    }
    ${SummaryFragment}
    ${ImageSummaryFragment}
`;
