import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment ImageBlockFragment on ImageBlock {
        blockName
        blockType
        image {
            ...ImageSummaryFragment
        }
        secondImage {
            ...ImageSummaryFragment
        }
        type
        caption
        ImageBlock___width: width
    }
    ${ImageSummaryFragment}
`;
