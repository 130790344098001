import { gql } from 'graphql-tag';

export default gql`
    fragment VideoPosterFragment on VideoPoster {
        url
        altText
        caption
        width
        height
        youtubeUrl {
            newTab
            url
        }
    }
`;
