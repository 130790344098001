import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import FileFragment from '@fragments/shared/FileFragment';
export default gql`
    fragment ButtonBlockFragment on ButtonBlock {
        blockName
        blockType
        button {
            appearance
            label
            type
            url
            reference {
                ...SummaryFragment
            }
            file {
                ...FileFragment
            }
            socialNetworks {
                network
                handle
                id
            }
            newTab
        }
    }
    ${SummaryFragment}
    ${FileFragment}
`;
