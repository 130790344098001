import { gql } from 'graphql-tag';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';

export default gql`
    fragment PullQuoteBlockFragment on PullQuoteBlock {
        blockName
        blockType
        colorBarOverride
        person {
            ...PersonSummaryFragment
        }
        quote
        PullQuoteBlock___width: width
    }
    ${PersonSummaryFragment}
`;
