import { gql } from 'graphql-tag';
import VideoPosterFragment from '@fragments/shared/VideoPosterFragment';

export default gql`
    fragment VideoBlockFragment on VideoBlock {
        blockName
        blockType
        video {
            ...VideoPosterFragment
        }
        VideoBlock___width: width
    }
    ${VideoPosterFragment}
`;
