import { gql } from 'graphql-tag';

import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';
import VideoPosterFragment from '@fragments/shared/VideoPosterFragment';

export default gql`
    fragment EventPanelBlockFragment on EventPanelBlock {
        blockType
        buttons {
            button {
                appearance
                label
                type
                url
            }
        }
        description
        eventDate {
            start
            end
            timeZone
            startTimestamp
            endTimestamp
        }
        eventTitle
        eyebrow
        peopleGroups {
            groupTitle
            contributors {
                ...PersonSummaryFragment
            }
        }
        subheader
        video {
            ...VideoPosterFragment
        }
    }

    ${ImageSummaryFragment}
    ${PersonSummaryFragment}
    ${VideoPosterFragment}
`;
