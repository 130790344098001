import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import FileFragment from '@fragments/shared/FileFragment';

export default gql`
    fragment GalleryBlockFragment on GalleryBlock {
        blockName
        blockType
        buttons {
            id
            GalleryBlock___button: button {
                appearance
                type
                label
                reference {
                    ...SummaryFragment
                }
                file {
                    ...FileFragment
                }
            }
        }
        GalleryBlock___header: header {
            headerTitle
            type
        }
        images {
            ...ImageSummaryFragment
        }
        subheader
        GalleryBlock___width: width
    }
    ${ImageSummaryFragment}
    ${SummaryFragment}
    ${FileFragment}
`;
