import { gql } from 'graphql-tag';
import NewsletterFragment from '@fragments/pages/NewsletterFragment';
export default gql`
    fragment SubscribeBlockFragment on SubscribeBlock {
        blockName
        blockType
        newsletter {
            ...NewsletterFragment
        }
        newsletterFormName
        SubscribeBlock___width: width
    }
    ${NewsletterFragment}
`;
