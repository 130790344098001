import { gql } from 'graphql-tag';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';

export default gql`
    fragment PersonBlockFragment on PersonBlock {
        blockName
        blockType
        descriptionOverride
        person {
            ...PersonSummaryFragment
        }
        personTitleOverride
        PersonBlock___width: width
    }
    ${PersonSummaryFragment}
`;
